import React from "react";
import Pdf2 from "../../medias/fiches/Fiscalite_SAFER.pdf";
import Pdf4 from "../../medias/fiches/Check_list_plus_values.pdf";
import Pdf6 from "../../medias/fiches/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_vente_(check-list).pdf";
import Pdf11 from "../../medias/fiches/Diagnostic_immobilier_le_dossier_de_diagnostic_technique_(DDT).pdf";
import Pdf12 from "../../medias/fiches/Droits_de_mutation_a_titre_onereux_(DMTO).pdf";
import Pdf16 from "../../medias/fiches/Le_bail_professionnel_et_le_bail_commercial.pdf";
import Pdf17 from "../../medias/fiches/La_fiscalite_immobiliere_quel_taux_et_quel_droit_appliquer.pdf";
import Pdf18 from "../../medias/fiches/La_location_pour_les_particuliers_quel_bail.pdf";
import "./informations.css";
import ReactGA from "react-ga4";

function FichesImmo(props) {
  const pictoFiche = (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 11.6 16.1"
      style={{ enableBackground: "new 0 0 11.6 16.1", width: "19px", height: "19px", paddingRight: "10px" }}
      xmlSpace="preserve"
    >
      <path
        fill="#ffffff"
        d="M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z M11.4,2.6L9.1,0.3C8.9,0.1,8.6,0,8.3,0H1.6C0.7,0,0,0.7,0,1.6v12.9c0,0.9,0.7,1.6,1.6,1.6H10c0.9,0,1.6-0.7,1.6-1.6V3.4C11.7,3.1,11.6,2.8,11.4,2.6z M11.2,14.5h-0.1c0,0.6-0.5,1.1-1.1,1.1H1.6c-0.6,0-1.1-0.5-1.1-1.1V1.6c0.1-0.6,0.5-1,1.1-1h6.6c0.2,0,0.3,0.1,0.4,0.2L11,3c0.1,0.1,0.2,0.2,0.2,0.4V14.5z M10.3,3.1H8.6V1.3C8.6,1.1,8.5,1,8.3,1C8.2,1,8,1.1,8,1.3v2.1c0,0.2,0.1,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3S10.5,3.1,10.3,3.1z"
      />
      <path
        fill="#005344"
        d="M11,3L8.6,0.8C8.5,0.7,8.4,0.6,8.2,0.6H1.6c-0.6,0-1,0.4-1.1,1v12.9c0,0.6,0.5,1.1,1.1,1.1H10
      c0.6,0,1.1-0.5,1.1-1.1h0.1V3.4C11.2,3.2,11.1,3.1,11,3z M10.3,3.7h-2C8.1,3.7,8,3.6,8,3.4V1.3C8,1.1,8.2,1,8.3,1
      c0.2,0,0.3,0.1,0.3,0.3v1.8h1.7c0.2,0,0.3,0.1,0.3,0.3S10.5,3.7,10.3,3.7z"
      />
    </svg>
  );

  const handleClick = (pdfName) => {
    ReactGA.event({
      category: "Fiches Immo",
      action: "Clic sur élément",
      label: pdfName,
    });

    window.open(pdfName, "_blank");
  };

  return (
    <>
      <div className="fiches-container-main">
        <div className="fiches-cardTitle">Fiches Pratiques Immobilier</div>
        <ul className="fiches-blockList">
          <div className="fiches-listElem" onClick={() => handleClick(Pdf2)}>
            {pictoFiche}
            La fiscalité des SAFER
          </div>

          <div className="fiches-listElem" onClick={() => handleClick(Pdf4)}>
            {pictoFiche}
            Check-list sur les plus-values
          </div>
          <div className="fiches-listElem" onClick={() => handleClick(Pdf6)}>
            {pictoFiche}
            Check-list lors d'une vente
          </div>
          <div className="fiches-listElem" onClick={() => handleClick(Pdf11)}>
            {pictoFiche}
            Les diagnostics immobiliers
          </div>
          {/*<div className="fiches-listElem" onClick={() => handleClick(Pdf12)}>*/}
          {/*  {pictoFiche}*/}
          {/*  Les DMTO au 1er Juin 2024*/}
          {/*</div>*/}

          <div className="fiches-listElem" onClick={() => handleClick(Pdf16)}>
            {pictoFiche}
            Le bail professionnel et le bail commercial
          </div>
          <div className="fiches-listElem" onClick={() => handleClick(Pdf17)}>
            {pictoFiche}
            La fiscalité immobilière, quel taux et quel droit appliquer ?
          </div>
          <div className="fiches-listElem" onClick={() => handleClick(Pdf18)}>
            {pictoFiche}
            La location pour les particuliers, quel bail ?
          </div>
        </ul>
      </div>
    </>
  );
}

export default FichesImmo;
